<ul class="navbar-nav ml-auto" *ngIf="!menuService.hideMenu && isAuthenticated | async">
  <li class="nav-item" *ngIf="idvEnabled || selfServeUser">
    <a routerLinkActive="active-route" class="nav-link text-dark cursor" [routerLink]='["/idv"]' title="eIDV">eIDV</a>
  </li>
  <li class="nav-item"  *ngIf="riskAssessmentEnabled || selfServeUser">
    <a routerLinkActive="active-route" class="nav-link text-dark cursor" [routerLink]='["/risk-assessment"]' title="Assessment">Assessment</a>
  </li>
  <li class="nav-item"  *ngIf="screeningEnabled || selfServeUser" >
    <a routerLinkActive="active-route" class="nav-link text-dark cursor" [routerLink]='["/screen"]' [state]='{ local: true, pageName: "screen", refresh: true }' title="Screen">Screen</a>
  </li>
  <li class="nav-item" *ngIf="monitoringEnabled || selfServeUser">
    <a routerLinkActive="active-route" class="nav-link text-dark cursor" [routerLink]='["/monitor"]' [state]='{ local: true, pageName: "monitor", refresh: true }' title="Monitor">Monitor</a>
  </li>
  <li class="nav-item" *ngIf="searchEnabled || selfServeUser">
    <a routerLinkActive="active-route" class="nav-link text-dark cursor" [routerLink]='["/search"]' title="Search">Search</a>
  </li>
  <li class="nav-item" *ngIf="intelEnabled || selfServeUser">
    <a routerLinkActive="active-route" *ngIf="intelEnabled" class="nav-link text-dark cursor" [routerLink]='["/intelligence"]' title="Intel">Intelligence</a>
  </li>
  <li class="nav-item" *ngIf="searchEnabled"> 
    <a routerLinkActive="active-route" class="nav-link text-dark" [routerLink]='["/history"]' [state]='{ local: true, pageName: "history", refresh: true }' title="History">History</a>
  </li>
  <li class="nav-item" ngbDropdown>
    <a ngbDropdownToggle class="nav-link text-dark" tabindex="0" id="navbarDropdown2" role="button">
      Reports
    </a>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown2">
      <a routerLinkActive="active-route" *ngIf="screeningEnabled" ngbDropdownItem class="dropdown-item" [routerLink]='["/initial-screen-audit"]'>Initial Screening Report</a>
    </div>
  </li>
  <li *ngIf="isAdministrator && !isThemisAdministrator" class="nav-item" ngbDropdown>
    <a ngbDropdownToggle class="nav-link text-dark" tabindex="0" id="navbarDropdown2" role="button">
      Admin
    </a>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown2">
      <a routerLinkActive="active-route" ngbDropdownItem class="dropdown-item" [routerLink]='["/user-management/manage-users"]'>Manage Users</a>
      <a routerLinkActive="active-route" *ngIf="searchEnabled || monitoringEnabled || screeningEnabled || selfServeUser" ngbDropdownItem class="dropdown-item" [routerLink]='["/feature-management/self-manage-features"]'>Screening Logic</a>
      <a routerLinkActive="active-route" *ngIf="riskAssessmentEnabled || selfServeUser" ngbDropdownItem class="dropdown-item" [routerLink]='["/feature-management/risk-assessment-feature"]'>Risk Assessment</a>
      <a routerLinkActive="active-route" *ngIf="APIEnabled || selfServeUser" ngbDropdownItem class="dropdown-item" [routerLink]='["/api-admin"]'>Web API Settings</a>
      <a routerLinkActive="active-route" *ngIf="isIntelligenceAdministrator" ngbDropdownItem class="dropdown-item" [routerLink]='["/intelligence-admin"]'>Intelligence Admin</a>
      <a routerLinkActive="active-route" *ngIf="searchEnabled || monitoringEnabled || screeningEnabled" ngbDropdownItem class="dropdown-item" [routerLink]='["/feature-management/system-features"]'>System Settings</a>
    </div>
  </li>

  <li class="nav-item" *ngIf="isThemisAdministrator">
    <a routerLinkActive="active-route" class="nav-link text-dark" [routerLink]='["/tenant-management/manage-tenants"]' title="Manage">Manage Tenants</a>
  </li>
  <li class="nav-item" ngbDropdown>
    <a ngbDropdownToggle class="nav-link text-dark" tabindex="0" id="navbarDropdown1" role="button">
      My Account
    </a>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown1">
      <a routerLinkActive="active-route" ngbDropdownItem class="dropdown-item" [routerLink]='["/user-management/manage-self"]'>Edit profile</a>
      <a ngbDropdownItem class="dropdown-item" href="https://themisservices.freshdesk.com/support/home" target="_blank">FAQ</a>
      <a ngbDropdownItem class="dropdown-item" [routerLink]='["/authentication/logout"]' [state]='{ local: true }' title="Logout">Logout</a>
    </div>
  </li>
</ul>
