<div class="input-group client-date p-0">
    <input [readonly]="false" class="form-control fst-italic fw-light" [(ngModel)]='ngbDate' dob="dob" #dob="ngModel" [minDate]="{year: 1900, month: 1, day: 1}" ngbDatepicker #d="ngbDatepicker" placeholder="yyyy-mm-dd" (ngModelChange)="onInputBlur()" (blur)="onInputBlur()">
    <span type="button" class="btn border" (click)="d.toggle()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
      </svg>
    </span>
    <div *ngIf="invalidDate" class="row">
      <div class="form-control alert alert-danger">
        Please select or enter a valid date in the format (yyyy-mm-dd).
      </div>
    </div>
</div>