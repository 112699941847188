import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SearchService } from '../search/services/search.service';
import { ShepherdService } from 'angular-shepherd';
import { steps as defaultSteps, defaultStepOptions } from '../home/data';
import { ProfileService } from '../profile.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnDestroy {
  constructor(public searchService: SearchService, public router: Router, private profileService: ProfileService, private guideTour: ShepherdService) { }

  ngOnDestroy() {
    this.guideTour = null;
  }

  public completeStep() {
    this.guideTour.complete();
  }

  riskAssessmentEnabled(): boolean {
    return this.profileService.riskAssessmentEnabled();
  }

  idvEnabled(): boolean {
    return this.profileService.idvEnabled();
  }
  searchEnabled(): boolean {
    return this.profileService.searchEnabled();
  }
  monitoringEnabled(): boolean {
    return this.profileService.monitoringEnabled();
  }
  screeningEnabled(): boolean {
    return this.profileService.screeningEnabled();
  }
  intelEnabled(): boolean {
    return this.profileService.intelligenceEnabled();
  }

  start() {
    this.guideTour.defaultStepOptions = defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
    this.guideTour.addSteps(defaultSteps);
    this.guideTour.start();
  }

  getVersionNumber() {
    return 'Version: ' + environment.version;
  }
}
