import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MonitorService } from 'src/app/monitor/services/monitor.service';
import { RiskAssessmentService } from 'src/app/risk-assessment/services/risk-assessment.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { Guid } from 'src/app/utils/guid';
import { AlertService } from 'src/app/_alert';
import { AddTenantCommand, ToggleDiligenciaDataProviderCommand, DataSourceProviderId, DateOfBirthMatching, DeleteTenantCommand, GetWorklistDetailsWorklist, IdentityManagementClient, ListTenantsQuery, ListTenantsModel, TenantManagementClient, UpdateAssessmentSettingsCommand, UpdateAcurisDataProviderSettingsCommand, UpdateMonitorSettingsCommand, UpdateMonitorSettingsCommandDataProviderSettings, UpdateTenantCommand, DataProviderSettingsModel } from 'src/nswag';
import { ConfirmationDialogService } from 'src/app/_confirmation-dialog/ConfirmationDialog.service';
import { UtilityService } from 'src/app/utilitities/utilities';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-manage-tenants',
  templateUrl: './manage-tenants.component.html',
  styleUrls: ['./manage-tenants.component.scss']
})
export class ManageTenantsComponent {
  @ViewChild('addTenantModalContent', { static: false }) addTenantModalContent: TemplateRef<void>;
  @ViewChild('updateTenantModalContent', { static: false }) updateTenantModalContent: TemplateRef<void>;
  @ViewChild('deleteTenantModalContent', { static: false }) deleteTenantModalContent: TemplateRef<void>;

  constructor(public activeModal: NgbActiveModal, public tenantManagementClient: TenantManagementClient, private modalService: NgbModal, private router: Router,
    private alertService: AlertService, private monitorService: MonitorService, private dataProviderService: DataProviderService,
    private riskAssessmentService: RiskAssessmentService, private identityClient: IdentityManagementClient, private confirmationService: ConfirmationDialogService,
    private errorService: ErrorService) {
    this.getTenantsList();
    this.getMonitorDobOptions();
    this.setModuleConfigDefaults();
    this.errorService.reset();
  }

  ngOnDestroy() {
    // close all modal pop upon component destroy
    this.modalService.dismissAll(false);
  }

  tenantsList: ListTenantsModel[];
  selectedTenant: ListTenantsModel;
  searchFeature: boolean;
  idvFeature: boolean;
  monitorFeature: boolean;
  screenFeature: boolean;
  riskFeature: boolean;
  apiFeature: boolean;
  intelligenceFeature: boolean;
  dobSensitivityOptions: DateOfBirthMatching[] = [];
  selectedDobOption;
  currentDobSensitivity;
  configurableThreshold: number;
  currentThreshold: number;
  addTenantCommand: AddTenantCommand = new AddTenantCommand();
  togglePremiumSearchProviderCommand: ToggleDiligenciaDataProviderCommand = new ToggleDiligenciaDataProviderCommand();
  modalRef: NgbModalRef;
  worklistConfig: GetWorklistDetailsWorklist;
  tenantsExistingFeatures: string[] = [];
  tenantsExistingDataProviders: DataProviderSettingsModel[] = [];

  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    scrollable: true
  };
  alertServiceOptions = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  private getMonitorDobOptions() {
    this.dobSensitivityOptions = Object.values(DateOfBirthMatching).filter((item) => {
      return isNaN(Number(item));
    });
  }

  public changeSelectedDropdownItem(selectedItem: string) {
    this.selectedDobOption = selectedItem;
  }

  private setModuleConfigDefaults() {
    this.configurableThreshold = 85;
    this.selectedDobOption = this.dobSensitivityOptions[4]; // 3 years
  }

  private getTenantsList() {
    var command: ListTenantsQuery = new ListTenantsQuery();
    var self = this;
    this.tenantManagementClient.list(command).subscribe(result => {
      self.tenantsList = result.data;
    });
  }

  public showCreateTenantModal(): Promise<any> {
    this.setModuleConfigDefaults();
    const modalRef = this.modalService.open(this.addTenantModalContent, this.modalOptions);
    this.modalRef = modalRef;
    return modalRef.result;
  }

  public showEditTenantModal(tenant: ListTenantsModel) {
    this.selectedTenant = tenant;
    this.tenantsExistingFeatures = tenant?.features;
    this.searchFeature = tenant.features?.findIndex(v => v == "Search") > -1;
    this.idvFeature = tenant.features?.findIndex(v => v == "IDV") > -1;
    this.monitorFeature = tenant.features?.findIndex(v => v == "Monitor") > -1;
    this.screenFeature = tenant.features?.findIndex(v => v == "Screen") > -1;
    this.riskFeature = tenant.features?.findIndex(v => v == "RiskAssessment") > -1;
    this.apiFeature = tenant.features?.findIndex(v => v == "API") > -1;
    this.intelligenceFeature = tenant.features?.findIndex(v => v == "Intelligence") > -1;

    if (this.monitorFeature) {
      this.monitorService.getWorklistDetails(tenant.tenantId).subscribe(res => {
        if (res.isSuccess) {
          this.worklistConfig = res?.data;
        }
      });
    }

    if (this.searchFeature || this.screenFeature || this.monitorFeature) {
      this.dataProviderService.getTenantDataProviderDetails(tenant.tenantId).subscribe(res => {
        if (res.isSuccess) {
          this.tenantsExistingDataProviders = res?.data?.providers;
          var acurisProvider = this.tenantsExistingDataProviders?.find(x => x?.name == DataSourceProviderId.Acuris);
          var diligenciaProvider = this.tenantsExistingDataProviders?.find(x => x?.name == DataSourceProviderId.Diligencia);
          this.togglePremiumSearchProviderCommand.enable = diligenciaProvider != null ? true : false;
          this.configurableThreshold = acurisProvider?.threshold;
          this.currentThreshold = acurisProvider?.threshold;
          this.currentDobSensitivity = acurisProvider?.dobSensitivity;
          this.changeSelectedDropdownItem(acurisProvider?.dobSensitivity);
        }
      });
    }
    const modalRef = this.modalService.open(this.updateTenantModalContent, this.modalOptions);
    this.modalRef = modalRef;
    return modalRef.result;
  }

  public showDeleteTenantConfirmModal(tenant: ListTenantsModel) {
    this.selectedTenant = tenant;
    const modalRef = this.modalService.open(this.deleteTenantModalContent, this.modalOptions);
    this.modalRef = modalRef;
    return modalRef.result;
  }

  public loadUserManagementForTenant(tenant: ListTenantsModel) {
    this.router.navigate(['/user-management/manage-users'], {
      queryParams: {
        tenantId: tenant.tenantId
      },
      queryParamsHandling: 'merge',
    });
  }

  createAPIClient(tenantId: Guid) {
    this.identityClient.configureClient(tenantId.toString()).subscribe(res => {
      if (res.errors) {
        this.alertService.error('Something went wrong when configuring the API client. Please try again later or contact our support team.');
      }
    });
  }

  canViewForm(): boolean {
    return this.addTenantCommand != null;
  }

  dismissActiveModal() {
    this.modalRef.dismiss();
  }

  cancelForm() {
    this.dismissActiveModal();
    this.clearNgModels();
  }

  private clearNgModels() {
    this.selectedTenant = new ListTenantsModel();
    this.addTenantCommand = new AddTenantCommand();
    this.togglePremiumSearchProviderCommand = new ToggleDiligenciaDataProviderCommand();
    this.selectedDobOption = null;
    this.configurableThreshold = null;
    this.resetFeatures();
  }

  private resetFeatures() {
    this.monitorFeature = false;
    this.searchFeature = false;
    this.screenFeature = false;
    this.idvFeature = false;
    this.apiFeature = false;
    this.riskFeature = false;
    this.intelligenceFeature = false;
  }

  public maxUsersValid(users: number): boolean {
    return users && users > 0 ? true : false;
  }

  public dataProviderSettingsValid(): boolean {
    var isValid = true;
    if (this.screenFeature || this.searchFeature || this.monitorFeature) {
      isValid = this.thresholdIsValid() && this.selectedDobOption;
    }
    return isValid;
  }

  addTenant() {
    this.addTenantCommand.features = this.getSelectedFeatures();
    this.tenantManagementClient.create(this.addTenantCommand).subscribe(result => {
      if (!result.errors) {
        var tenantId = result?.data;
        this.alertService.success('Tenant created successfully', this.alertServiceOptions);
        this.getTenantsList();

        if (this.searchFeature || this.screenFeature || this.monitorFeature) {
          this.configureDataProvider(tenantId);
        }

        if (this.monitorFeature) {
          this.configureMonitoring(tenantId);
        }

        if (this.riskFeature) {
          this.configureRiskAssessment(tenantId);
        }

        if (this.apiFeature) {
          this.createAPIClient(tenantId);
        }

        if (this.intelligenceFeature) {
          this.configureIntelligence(tenantId);
        }

        if (this.searchFeature && this.togglePremiumSearchProviderCommand?.enable) {
          this.togglePremiumSearch(tenantId);
        }

        this.clearNgModels();
      }
      this.clearNgModels();
    }, (error: any) => {
      const errorMessages = JSON.parse(error.response)?.errors;
      if (errorMessages && errorMessages.length) {
        errorMessages.forEach(
          (err: { errorMessage: string; }) => {
            return this.alertService.error(err.errorMessage, this.alertServiceOptions);
          })
      } else {
        this.alertService.error('There was a problem creating the tenant, please try again', this.alertServiceOptions);
      }
      console.log(error);
    });
    this.dismissActiveModal();
  }

  public configureMonitoring(tenantId: string) {
    this.errorService.reset();
    var command = new UpdateMonitorSettingsCommand();
    var dataProviderSettings = new UpdateMonitorSettingsCommandDataProviderSettings();
    dataProviderSettings.dobSensitivity = this?.selectedDobOption;
    dataProviderSettings.dataProviderThreshold = this?.configurableThreshold;
    command.providerSettings = dataProviderSettings;
    command.tenantId = tenantId;
    this.monitorService.updateMonitorSettings(command).subscribe(res => {
      if (res.isSuccess) {
        this.alertService.success('Monitor Settings Updated', this.alertServiceOptions);
      }
    }, error => {
      this.errorService.setProblemDetailsFromError(error);
    });
  }

  public togglePremiumSearch(tenantId: string) {
    this.togglePremiumSearchProviderCommand.tenantId = tenantId;
    this.dataProviderService.configurePremiumSearchProvider(this.togglePremiumSearchProviderCommand).subscribe(res => {
      if (res.errors) {
        this.alertService.error('Something went wrong when toggling premium search.');
      }
    });
  }
  public configureRiskAssessment(tenantId: string) {
    this.errorService.reset();
    var command = new UpdateAssessmentSettingsCommand();
    command.emailMessage = RiskAssessmentService.defaultEmailMessage,
      command.welcomeMessage = RiskAssessmentService.defaultWelcomeMessage,
      command.tenantId = tenantId,

      this.riskAssessmentService.updateAssessmentSettings(command).subscribe(res => {
        if (res.isSuccess) {
          this.alertService.success('Assessment Settings Updated', this.alertServiceOptions);
        }
      }, error => {
        this.errorService.setProblemDetailsFromError(error);
      });
  }

  public configureIntelligence(tenantId: string) {
    //TODO
  }

  public configureDataProvider(tenantId: string) {
    this.errorService.reset();
    var command = new UpdateAcurisDataProviderSettingsCommand();
    command.tenantId = tenantId;
    command.dataProviderThreshold = this.configurableThreshold;
    command.dobSensitivity = this.selectedDobOption;
    this.dataProviderService.updateDataProviderSettings(command).subscribe(res => {
      if (res.isSuccess) {
        this.alertService.success('Data Provider Settings Updated', this.alertServiceOptions);
      }
    }, error => {
      this.errorService.setProblemDetailsFromError(error);
    });
  }

  public thresholdIsValid() {
    return this.configurableThreshold && this.configurableThreshold >= 65 && this.configurableThreshold <= 100;
  }

  public updateTenant() {
    this.errorService.reset();
    var command: UpdateTenantCommand = new UpdateTenantCommand();
    command.tenantId = this.selectedTenant?.tenantId;
    command.tenantName = this.selectedTenant?.name;
    command.tenantDescription = this.selectedTenant?.description;
    command.maxActiveUsers = this.selectedTenant.maxActiveUsers;
    command.companyName = this.selectedTenant?.companyName;
    command.features = this.getSelectedFeatures();
    command.demo = this.selectedTenant?.demo;

    this.tenantManagementClient.update(command).subscribe(result => {
      this.alertService.success('Tenant updated successfully', this.alertServiceOptions);
      this.getTenantsList();

      if (this.monitorFeature && !this.tenantsExistingFeatures.includes('Monitor')) {
        this.configureMonitoring(this.selectedTenant?.tenantId);
      }
      if (this.searchFeature && this.canTogglePremiumDataProvider()) {
        this.togglePremiumSearch(this.selectedTenant?.tenantId);
      }
      if (this.riskFeature) {
        const tenantId = this.selectedTenant?.tenantId;
        this.riskAssessmentService.getAssessmentSettings(tenantId).subscribe(res => {
          if (res.isSuccess) {
            if (!res?.data.welcomeMessage) {
              this.configureRiskAssessment(tenantId);
            }
          }
        });
      }
      if (this.apiFeature && !this.tenantsExistingFeatures.includes('API')) {
        this.createAPIClient(this.selectedTenant?.tenantId);
      }
      if (this.intelligenceFeature && !this.tenantsExistingFeatures.includes('Intelligence')) {
        this.configureIntelligence(this.selectedTenant?.tenantId);
      }
      if ((this.searchFeature && !this.tenantsExistingFeatures.includes('Search')) ||
        (this.screenFeature && !this.tenantsExistingFeatures.includes('Screen')) ||
        (this.monitorFeature && !this.tenantsExistingFeatures.includes('Monitor')) ||
        this.dataProviderSettingsHaveChanged()) {
        let message = UtilityService.getUpdateSettingsMessageText(this.monitorFeature, this.searchFeature, this.screenFeature);
        //open model with warning
        this.confirmationService.confirm("Update Data Provider?", `Are you sure? ${message}`, false, "Yes", "No")
          .then((confirmed) => {
            if (confirmed) {
              this.configureDataProvider(this.selectedTenant?.tenantId);
            }
            this.clearNgModels();
          })
          .catch(() => {
            this.clearNgModels();
          });
      }
      this.dismissActiveModal();
    }, error => {
      this.errorService.setProblemDetailsFromError(error);
    });
  }

  public tenantFeaturesChanged(): boolean {
    var selectedFeatures = this.getSelectedFeatures();
    return selectedFeatures.sort().toString() !== this.tenantsExistingFeatures.sort().toString();
  }

  public canTogglePremiumDataProvider(): boolean {
    var canToggle = false;
    var premiumProvider = this.tenantsExistingDataProviders?.find(x => x?.name == DataSourceProviderId.Diligencia);

    if ((premiumProvider != null && this.togglePremiumSearchProviderCommand.enable == false) ||
      (premiumProvider == null && this.togglePremiumSearchProviderCommand.enable == true)) {
      canToggle = true;
    }
    return canToggle;
  }

  public dataProviderSettingsHaveChanged(): boolean {
    return this.thresholdHasChanged() || this.dobSensitivityHasChanged();
  }

  public thresholdHasChanged(): boolean {
    return this.currentThreshold != this.configurableThreshold;
  }

  public dobSensitivityHasChanged(): boolean {
    return this.currentDobSensitivity != this.selectedDobOption;
  }

  private getSelectedFeatures(): string[] {
    let flags: string[] = [];
    if (this.searchFeature) {
      flags.push("Search");
    }
    if (this.idvFeature) {
      flags.push("IDV");
    }
    if (this.monitorFeature) {
      flags.push("Monitor");
    }
    if (this.screenFeature) {
      flags.push("Screen");
    }
    if (this.riskFeature) {
      flags.push("RiskAssessment");
    }
    if (this.apiFeature) {
      flags.push("API");
    }
    if (this.intelligenceFeature) {
      flags.push("Intelligence");
    }
    return flags;
  }

  public deleteTenant() {
    var self = this;
    var command: DeleteTenantCommand = new DeleteTenantCommand();
    command.tenantId = this.selectedTenant.tenantId;
    this.tenantManagementClient.delete(command).subscribe(result => {
      self.alertService.success('Tenant removed successfully', self.alertServiceOptions);
      self.getTenantsList();
    }, (error: any) => {
      self.alertService.error('There was a problem deleting the tenant, please try again', self.alertServiceOptions);
      console.log(error);
    });
    this.dismissActiveModal();
  }
}
