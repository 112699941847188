import { APP_ID, NgModule } from '@angular/core';
import { NgbActiveModal, NgbDropdown, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle, RouteReuseStrategy, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ScriptService } from './scripts.service';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { RecipientManagementClient, AssessmentManagementClient, AssessmentSupportClient, DataProviderClient, DueDiligenceClient, IdentityManagementClient, SurveyClient, TenantManagementClient, RecipientClient, AdminClient, ConfigurationClient, UserInteractionClient, SelfServeClient, IntelligenceClient } from 'src/nswag';
import { SearchComponent } from './search/search.component';
import { HistoryComponent } from './history/history.component';
import { AlertModule } from './_alert';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { ZignSecClient, MediaClient, UserManagementClient, AccountManagementClient, RoleManagementClient } from 'src/nswag';
import { ThemisHttpInterceptor } from './http.interceptor';
import { ManageUsersComponent } from './user-management/manage-users/manage-users.component';
import { ManageSelfComponent } from './user-management/manage-self/manage-self.component';
import { ConfirmationDialogComponent } from './_confirmation-dialog/_confirmation-dialog.component';
import { ConfirmationDialogService } from './_confirmation-dialog/ConfirmationDialog.service';
import { SearchModule } from './search/search.module';
import { PrintPageComponent } from './print-page/print-page.component';
import { MainPageComponent } from './main-page/main-page.component';
import { PreviewPageComponent } from './preview-page/preview-page.component';
import { LoaderModule } from './loader/loader.module';
import { ManageTenantsComponent } from './tenant-management/manage-tenants/manage-tenants.component';
import { MonitorComponent } from './monitor/monitor.component';
import { MonitorModule } from './monitor/monitor.module';
import { ScreeningModule } from './screening/screening.module';
import { ScreeningComponent } from './screening/screening.component';
import { UserSatisfactionComponent } from './surveys/user-satisfaction/user-satisfaction.component';
import { SelfManageFeaturesComponent } from './feature-management/self-manage-features/self-manage-features.component';
import { RiskAssessmentComponent } from './risk-assessment/risk-assessment.component';
import { IdvModule } from './idv/idv.module';
import { IdvStartComponent } from './idv/idvStart/idvStart.component';
import { IdvComponent } from './idv/idv/idv.component';
import { NgIdleModule } from '@ng-idle/core';
import { RiskAssessmentFeatureComponent } from './feature-management/risk-assessment-feature/risk-assessment-feature.component';
import { PipesModule } from './pipes/pipes.module';
import { RiskAssessmentEmailValidatorDirective } from './risk-assessment/utilities/risk-assessment-email-validator.directive';
import { RiskAssessmentWelcomeMessageValidatorDirective } from './risk-assessment/utilities/risk-assessment-welcome-message-validator.directive';
import { TagsModule } from './tags/tags.module';
import { RiskAssessmentModule } from './risk-assessment/risk-assessment.module';
import { ApiAdminComponent } from './api-admin/api-admin.component';
import { SelfServiceModule } from './self-service/self-service.module';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { DatePickerModule } from './date-picker/date-picker.module';
import { AuditComponent } from './audit/audit.component';
import { AuditModule } from './audit/audit.module';
import { ClientArchiveComponent } from './client-archive/client-archive.component';
import { ClientArchiveModule } from './client-archive/client-archive.module';
import { IntelligenceAdminComponent } from './intelligence/intelligence-admin/intelligence-admin.component';
import { IntelligenceModule } from './intelligence/intelligence.module';
import { IntelligenceAdminListComponent } from './intelligence/intelligence-admin/intelligence-admin-list/intelligence-admin-list.component';
import { IntelligenceComponent } from './intelligence/intelligence.component';
import { ProfileRequestModule } from './profile-request/profile-request.module';
import { SystemFeaturesComponent } from './feature-management/system-features/system-features.component';
import { ErrorService } from './services/error.service';
import { ProblemDetailsComponent } from './problem-details/problem-details.component';
import { ProblemDetailsModule } from './problem-details/problem-details/problem-details.module';

interface RouteStorageObject {
    snapshot: ActivatedRouteSnapshot;
    handle: DetachedRouteHandle;
}
class ThemisReuseStrategy extends BaseRouteReuseStrategy {
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        // let f: any = future;
        // let to: string = f?._routerState?.url;
        // let c: any = curr;
        // let from: string = c?._routerState?.url;
        // if (from == "/dashboard") {
        //   return true;
        // }
        return false;
    }
}
@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        HistoryComponent,
        ManageUsersComponent,
        ManageSelfComponent,
        ManageTenantsComponent,
        ConfirmationDialogComponent,
        ManageSelfComponent,
        PrintPageComponent,
        MainPageComponent,
        PreviewPageComponent,
        UserSatisfactionComponent,
        SelfManageFeaturesComponent,
        SystemFeaturesComponent,
        RiskAssessmentFeatureComponent,
        RiskAssessmentEmailValidatorDirective,
        RiskAssessmentWelcomeMessageValidatorDirective,
        ApiAdminComponent         
    ],
    imports: [
        ProblemDetailsModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        ApiAuthorizationModule,
        CommonModule,
        DashboardModule,
        AlertModule,
        SearchModule,
        ClientArchiveModule,
        LoaderModule,
        MonitorModule,
        ScreeningModule,
        RiskAssessmentModule,
        AuditModule,
        TagsModule,
        IdvModule,
        PipesModule,
        SelfServiceModule,
        DatePickerModule,
        IntelligenceModule,
        ProfileRequestModule,
        NgIdleModule.forRoot(),
        RouterModule.forRoot([
            { path: '', component: HomeComponent, canActivate: [AuthorizeGuard] },
            { path: ':module/archive', component: ClientArchiveComponent, canActivate: [AuthorizeGuard] },
            { path: 'screen', component: ScreeningComponent, canActivate: [AuthorizeGuard] },
            { path: 'monitor', component: MonitorComponent, canActivate: [AuthorizeGuard] },
            { path: 'search', component: SearchComponent, canActivate: [AuthorizeGuard] },
            { path: 'history', component: HistoryComponent, canActivate: [AuthorizeGuard] },
            { path: 'idv', component: IdvComponent, canActivate: [AuthorizeGuard] },
            { path: 'idvStart', component: IdvStartComponent, canActivate: [AuthorizeGuard] },
            { path: 'search/dashboard', component: DashboardComponent, canActivate: [AuthorizeGuard] },
            { path: 'user-management/manage-users', component: ManageUsersComponent, canActivate: [AuthorizeGuard] },
            { path: 'user-management/manage-self', component: ManageSelfComponent, canActivate: [AuthorizeGuard] },
            { path: 'tenant-management/manage-tenants', component: ManageTenantsComponent, canActivate: [AuthorizeGuard] },
            { path: 'feature-management/self-manage-features', component: SelfManageFeaturesComponent, canActivate: [AuthorizeGuard] },
            { path: 'risk-assessment', component: RiskAssessmentComponent, canActivate: [AuthorizeGuard] },
            { path: 'feature-management/risk-assessment-feature', component: RiskAssessmentFeatureComponent, canActivate: [AuthorizeGuard] },
            { path: 'feature-management/system-features', component: SystemFeaturesComponent, canActivate: [AuthorizeGuard] },
            { path: 'api-admin', component: ApiAdminComponent, canActivate: [AuthorizeGuard] },
            { path: 'initial-screen-audit', component: AuditComponent, canActivate: [AuthorizeGuard] },
            { path: 'intelligence-admin', component: IntelligenceAdminComponent, canActivate: [AuthorizeGuard] },
            { path: 'intelligence-admin-list', component: IntelligenceAdminListComponent, canActivate: [AuthorizeGuard] },
            { path: 'intelligence', component: IntelligenceComponent, canActivate: [AuthorizeGuard] },
        ], { onSameUrlNavigation: 'reload' })
    ],
    providers: [
        { provide: APP_ID, useValue: 'ng-cli-universal' },
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ThemisHttpInterceptor, multi: true },
        { provide: RouteReuseStrategy, useClass: ThemisReuseStrategy },
        DueDiligenceClient,
        ScriptService,
        ZignSecClient,
        MediaClient,
        UserManagementClient,
        TenantManagementClient,
        AccountManagementClient,
        DataProviderClient,
        RoleManagementClient,
        UserInteractionClient,
        AssessmentManagementClient,
        RecipientManagementClient,
        AssessmentSupportClient,
        AdminClient,
        RecipientClient,
        SurveyClient,
        NgbActiveModal,
        NgbDropdown,
        IdentityManagementClient,
        ConfigurationClient,
        SelfServeClient,
        { provide: Window, useValue: window },
        { provide: "googleTagManagerId", useValue: "GTM-MNZHFJ4"},
        GoogleTagManagerService,
        ConfirmationDialogService,
        IntelligenceClient,
        ErrorService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }