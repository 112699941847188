import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CountriesService } from '../utils/countries.service';
import { ListClientsQuery} from 'src/nswag';
import { ScreeningService } from 'src/app/screening/services/screening.service';
import { DatePickerResult } from '../date-picker/date-picker.component';

@Component({
  selector: 'advanced-search-options',
  templateUrl: './advanced-search-options.component.html',
  styleUrls: ['./advanced-search-options.component.scss']
})

export class AdvancedSearchOptionsComponent {
  
  @Input() advancedQuery: ListClientsQuery;
  public ngbDate: NgbDateStruct;
  @Output() searchData = new EventEmitter<ListClientsQuery>();
  @Input() isMonitoring: boolean = false;

  constructor(private dateFormatter: NgbDateParserFormatter, private screeningService: ScreeningService) { }

  countryTypeAhead: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
  text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
          return this.countrySubSet(term);
      })
  );

  private countrySubSet(term: string) {
    return CountriesService.getJurisdictionSubSet(term);
  }

  public resetFilters(){
    this.ngbDate = null;
    this.advancedQuery = new ListClientsQuery;
    this.searchData.emit(this.advancedQuery);
  }
  
  public dateChanged(date: DatePickerResult) {
    if (date.isValid) {
      this.advancedQuery.dateOfBirth = this.safeDateCast(this.dateFormatter.format(date.value));
      this.composeSearchString();
    }
  }

  private safeDateCast(sdate: string): Date {
    let ddate: Date;
    try {
      ddate = new Date(sdate);
    }
    catch (error) {
      return null;
    }
    if (ddate instanceof Date) {
      if (isNaN(ddate.getTime())) {
        return null;
      }
      return ddate;
    }
    return null;
  }

  composeSearchString() {
    this.searchData.emit(this.advancedQuery);
  }
}