import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CountriesService } from 'src/app/utils/countries.service';
import { UtilityService } from 'src/app/utilitities/utilities';
import { AlertService } from 'src/app/_alert';
import { Address2, AlertStatus, BusinessClient, Client, ClientResult, IndividualClient, LAMPSStatus, MatchStatus, MonitorType } from 'src/nswag';
import { MonitorService } from '../../services/monitor.service';
import { DatePickerResult } from 'src/app/date-picker/date-picker.component';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'mon-clienteditor',
  templateUrl: './clienteditor.component.html',
  styleUrls: ['./clienteditor.component.scss']
})
export class ClientEditorComponent implements OnInit {
  public origclient: ClientResult;
  public editclient: ClientResult;
  public business: BusinessClient;
  public individual: IndividualClient;
  public isAdding: boolean;
  public isBusiness: boolean;
  public title: string;
  public buttonTitle: string;
  public addressCountry: string;
  public nationality: string;
  public jurisdiction: string;
  public clientExists: boolean;
  public ngbDate: NgbDateStruct;
  public isBusy: boolean = false;
  public dateIsValid = true;
  @Input() set Client(_client: ClientResult) { this.setClient(_client) };
  @Output() ClientChange = new EventEmitter<ClientResult>();

  constructor(private activeModal: NgbActiveModal, private dateFormatter: NgbDateParserFormatter, private alertService: AlertService, private monitorService: MonitorService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.errorService.reset();
    if (this.editclient) {
      return;
    }
    this.isAdding = true;
    this.isBusiness = false;
    this.title = this.buttonTitle = "Add Client";
    this.createEmptyEditClient();
  }

  public setClient(_client: ClientResult) {
    this.origclient = _client;
    if (!_client.client?.address) {
      _client.client.address = new Address2();
    }
    // Create a copy for editing
    this.editclient = new ClientResult();
    this.editclient.init(_client);
    this.isBusiness = this.editclient?.client.business ? true : false;
    this.buttonTitle = "Save Changes";
    if (this.isBusiness) {
      this.title = "Edit Business";
      this.business = this.editclient.client.business;
      this.jurisdiction = CountriesService.getJurisdictionName(this.business?.jurisdiction);
    }
    else {
      this.title = "Edit Person";
      if (this.editclient?.client?.individual?.dateOfBirth) {
        this.ngbDate = this.dateFormatter.parse(this.editclient?.client?.individual?.dateOfBirth);
      }
      
      this.individual = this.editclient.client.individual;
      this.individual.name = UtilityService.getIndividualFullName(this.individual.name, this.individual.firstName, this.individual.otherName, this.individual.lastName);
      this.nationality = CountriesService.getNationalityName(this.individual?.nationality);
    }
    this.addressCountry = CountriesService.getCountryName(this.editclient.client?.address?.countryIsoCode);
  }

  private createEmptyEditClient() {
    this.editclient = new ClientResult({
      client: new Client({
        individual: new IndividualClient(),
        business: new BusinessClient(),
        address: new Address2(),
      }),
      matchStatus: new MatchStatus({
        alertStatus: AlertStatus.Closed,
        matches: LAMPSStatus.No,
        isMatched: LAMPSStatus.No,
        isPEP: LAMPSStatus.No,
        hasLawEnforcement: LAMPSStatus.No,
        hasAdvMedia: LAMPSStatus.No,
        hasSanctions: LAMPSStatus.No,
      })
    });
    this.business = this.editclient.client.business;
    this.individual = this.editclient.client.individual;
  }

  public hasModelErrors(): boolean {
    var hasErrors = false;
    if (!this.business?.name && !this.individual?.name) hasErrors = true;
    else if (this.clientExists) hasErrors = true;
    else if (!this.dateIsValid) hasErrors = true;
    else if (!this.isBusiness && !this.isIndividualNameValid()) hasErrors = true;
    return hasErrors;
  }

  isIndividualNameValid(): boolean {
    if (!this.individual.name) return false;

    const match = /[^\p{Letter}\p{Mark}\s-]+/gu;
    const invalidCharacters =  this.individual.name.match(match);
    return invalidCharacters == null;
  }

  setClientType() {
    this.createEmptyEditClient();
    this.isBusiness = !this.isBusiness;
  }

  checkClientExists(clientRef: string, clientType: MonitorType): boolean {
    this.clientExists = false;
    if (clientRef?.length < 1) return;
    if (!this.isAdding) {
      var originalClientRef = this.origclient?.client?.business?.clientRef ?? this.origclient?.client?.individual?.clientRef;
      if (clientRef == originalClientRef) return;
    }
    this.monitorService.checkClientExists(clientRef, clientType).subscribe(res => {
      if (res.data) {
        this.clientExists = res.data;
      }
    });
    return this.clientExists;
  }
  checkBusinessClientExists(clientRef: string) {
    this.checkClientExists(clientRef, MonitorType.Businesses);
  }
  checkIndividualClientExists(clientRef: string) {
    this.checkClientExists(clientRef, MonitorType.Individuals);
  }
  
  dateChanged(dateResult: DatePickerResult) {
    this.dateIsValid = dateResult.isValid;
    if (this.dateIsValid) {
      this.ngbDate = dateResult.value;
      this.individual.dateOfBirth = this.dateFormatter.format(this.ngbDate);
    }
  }

  saveClient() {
    this.isBusy = true;
    if (this.isBusiness) {
      if (!this.business?.name) {
        this.alertService.error("Please provide a business name", { autoClose: true });
        this.isBusy = false;
        return;
      }
      if (this.jurisdiction?.length > 0 && UtilityService.getCodeFromCountry(this.jurisdiction).length == 0) {
        this.alertService.error("Please provide a valid country or leave it empty", { autoClose: true });
        this.isBusy = false;
        return;
      }
      if (this.addressCountry?.length > 0 && UtilityService.getCodeFromCountry(this.addressCountry).length == 0) {
        this.alertService.error("Please provide a valid country or leave it empty", { autoClose: true });
        this.isBusy = false;
        return;
      }
      if (this.clientExists) {
        this.alertService.error("The unique client reference ID is already mapped to a client in Monitoring", { autoClose: true });
        this.isBusy = false;
        return;
      }
      if (this.addressCountry) {
        this.editclient.client.address.countryIsoCode = CountriesService.getCountryCode(this.addressCountry);
      } else {
        this.editclient.client.address.countryIsoCode = null;
      }
      if (this.jurisdiction) {
        this.editclient.client.business.jurisdiction = CountriesService.getJurisdictionCode(this.jurisdiction);
      } else {
        this.editclient.client.business.jurisdiction = null;
      }
      this.editclient.client.individual = null;
      this.ClientChange.emit(this.editclient);
    }
    else {
      if (!this.individual?.name) {
        this.alertService.error("Please provide a name", { autoClose: true });
        this.isBusy = false;
        return;
      }
      if (this.nationality?.length > 0 && UtilityService.getCodeFromNationality(this.nationality).length == 0) {
        this.alertService.error("Please provide a valid nationality or leave it empty", { autoClose: true });
        this.isBusy = false;
        return;
      }
      if (this.addressCountry?.length > 0 && UtilityService.getCodeFromCountry(this.addressCountry).length == 0) {
        this.alertService.error("Please provide a valid country or leave it empty", { autoClose: true });
        this.isBusy = false;
        return;
      }
      if (this.clientExists) {
        this.alertService.error("The unique client reference ID is already mapped to a client in Monitoring", { autoClose: true });
        this.isBusy = false;
        return;
      }
      if (this.addressCountry) {
        this.editclient.client.address.countryIsoCode = CountriesService.getCountryCode(this.addressCountry);
      } else {
        this.editclient.client.address.countryIsoCode = null;
      }
      if (this.nationality) {
        this.editclient.client.individual.nationality = CountriesService.getNationalityCode(this.nationality);
      } else {
        this.editclient.client.individual.nationality = null;
      }
      this.editclient.client.business = null;
      this.ClientChange.emit(this.editclient);
    }
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  nationalityTypeAhead: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return this.nationalitySubSet(term);
      })
    );

  countryTypeAhead: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return this.countrySubSet(term);
      })
    );

  addressCountryTypeAhead: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return this.countrySubSet(term);
      })
    );

  private countrySubSet(term: string) {
    return CountriesService.getJurisdictionSubSet(term);
  }

  private nationalitySubSet(term: string) {
    return CountriesService.getNationalitySubSet(term);
  }
}
