import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Profile } from 'oidc-client';
import { Observable } from 'rxjs';
import { ProfileService } from 'src/app/profile.service';
import { SelfServiceService } from 'src/app/self-service/services/self-service.service';
import { AuthorizeService } from '../authorize.service';
import { LoginMenuService } from './login-menu.service';

@Component({
  selector: 'app-login-menu',
  templateUrl: './login-menu.component.html',
  styleUrls: ['./login-menu.component.css']
})

export class LoginMenuComponent implements OnInit, AfterViewInit {
  public hideMenu = false;
  public isAuthenticated: Observable<boolean>;
  public isAdministrator: boolean;
  public isThemisAdministrator: boolean;
  public isIntelligenceAdministrator: boolean;
  public isAdminChecking: boolean;

  public idvEnabled = false;
  public searchEnabled = false;
  public monitoringEnabled = false;
  public screeningEnabled = false;
  public riskAssessmentEnabled = false;
  public APIEnabled = false;
  public intelEnabled = false;
  public selfServeUser = false;


  constructor(
    private authorizeService: AuthorizeService, 
    public menuService: LoginMenuService,
    private profileService: ProfileService,
    private selfServeService: SelfServiceService) 
  {
  }

  ngOnInit() {
    
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    var loggedIn = false;
    this.isAuthenticated.subscribe(authenticated => {
      if (authenticated) {
        loggedIn = true;
      }      
    });
    this.authorizeService.getUser().subscribe(user => {      
      if (loggedIn) {
        var profile = user as Profile;
        this.profileService.setProfile(profile);
        this.idvEnabled = this.profileService.idvEnabled();
        this.searchEnabled = this.profileService.searchEnabled();
        this.monitoringEnabled = this.profileService.monitoringEnabled();
        this.screeningEnabled = this.profileService.screeningEnabled();
        this.riskAssessmentEnabled = this.profileService.riskAssessmentEnabled();
        this.APIEnabled = this.profileService.APIEnabled();
        this.intelEnabled = this.profileService.intelligenceEnabled();
        this.selfServeUser = this.profileService.isSelfServeUser();
        this.selfServeService.SetSelfServeUser(this.selfServeUser);
     
        this.isAdministrator = this.profileService.IsAdministrator;
        this.isThemisAdministrator = this.profileService.IsThemisAdministrator;
        this.isIntelligenceAdministrator = this.profileService.IsIntelligenceAdministrator;

        if (this.selfServeUser){
          this.selfServeService.getCredits();
          this.selfServeService.getUserPreferences();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.waitForElm('#fc_widget').then((elm) => {
      var w: any = window;
      if (w.fcWidget && this.profileService.getProfile()) {
        w.fcWidget.user.setFirstName(this.profileService.firstName);
        w.fcWidget.user.setEmail(this.profileService.email);
        w.fcWidget.user.setProperties({
          plan: "Pro",                 // meta property 1
          status: "Active"                // meta property 2
        });
      }
    });
  }

  private waitForElm(selector) {
    return new Promise(resolve => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(mutations => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector));
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    });
  }

}
