import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizeService } from './authorize.service';
import { filter, map, take, tap } from 'rxjs/operators';
import { ApplicationPaths, QueryParameterNames } from './api-authorization.constants';
import { ProfileService } from 'src/app/profile.service';
import { SelfServiceService } from 'src/app/self-service/services/self-service.service';
import { FeatureState, GoogleTagClickEvents } from 'src/app/enums';
import { GTMService } from 'src/app/services/gtm.service';

@Injectable({
  providedIn: 'root'
})

export class AuthorizeGuard  {

  constructor(private authorize: AuthorizeService, private router: Router, private profileService: ProfileService, private selfServeService: SelfServiceService, private gtmService: GTMService) {
  }

  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.authorize.isAuthenticated()
        .pipe(tap(isAuthenticated => this.handleAuthorization(isAuthenticated, state)));
  }

  private handleAuthorization(isAuthenticated: boolean, state: RouterStateSnapshot) {
    if (!isAuthenticated) {
      this.reRouteToLogin(state);
    } else if(state.url == "/risk-assessment") {
      if (!this.profileService.riskAssessmentEnabled()) {
        this.reRouteToHome().subscribe((navComplete) => {
          if (navComplete) this.logAndAdvertise(FeatureState.ASSESSMENT, GoogleTagClickEvents.ASSESSMENT_NAV); 
        });
      }
    }
    else if(state.url == "/screen") {
      if (!this.profileService.screeningEnabled()) {
        this.reRouteToHome();
      }
    }
    else if(state.url == "/monitor") {
      if (!this.profileService.monitoringEnabled()) {
        this.reRouteToHome().subscribe((navComplete) => {
          if (navComplete) this.logAndAdvertise(FeatureState.MONITOR, GoogleTagClickEvents.MONITOR_NAV); 
        });
      }
    }
    else if(state.url == "/search") {
      if (!this.profileService.searchEnabled()) {
        this.reRouteToHome().subscribe((navComplete) => {
          if (navComplete) this.logAndAdvertise(FeatureState.SEARCH, GoogleTagClickEvents.SEARCH_NAV); 
        });
      }
    }
    else if(state.url == "/idv") {
      if (!this.profileService.idvEnabled()) {
        this.reRouteToHome().subscribe((navComplete) => {
          if (navComplete) this.logAndAdvertise(FeatureState.IDV, GoogleTagClickEvents.IDV_NAV); 
        });
      }
    }
    else if(state.url == "/history") {
      if (!this.profileService.idvEnabled() && !this.profileService.searchEnabled() ) {
        this.reRouteToHome();
      }
    }
    else if (state.url == "/api-admin") {
      if (!this.profileService.APIEnabled()) {
        this.reRouteToHome().subscribe((navComplete) => {
          if (navComplete) this.logAndAdvertise(FeatureState.APISETTINGS, GoogleTagClickEvents.API_SETTINGS_NAV); 
        });
      }
    }
    else if (state.url == "/feature-management/risk-assessment-feature") {
      if (!this.profileService.riskAssessmentEnabled()) {
        this.reRouteToHome().subscribe((navComplete) => {
          if (navComplete) this.logAndAdvertise(FeatureState.ASSESSMENTSETTINGS, GoogleTagClickEvents.ASSESSMENT_SETTINGS_NAV); 
        });
      }
    }
    else if(state.url == "/intelligence") {
      if (!this.profileService.intelligenceEnabled() ) {
        this.reRouteToHome().subscribe((navComplete) => {
          if (navComplete) this.logAndAdvertise(FeatureState.INTELLIGENCE, GoogleTagClickEvents.INTELLIGENCE_NAV); 
        });
      }
    }
  }

  private logAndAdvertise(feature: FeatureState, tagEventName: GoogleTagClickEvents) {
    if (this.profileService.isSelfServeUser()) {
      this.gtmService.add(tagEventName, this.profileService.userId);
    }
    this.selfServeService.openAdvertDialog(feature);
  }

  private reRouteToHome(): Observable<boolean> {
    this.router.navigate(['/']);
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      take(1), // Ensure we only take the first navigation event
      map(() => true) // Emit true when navigation is completed
    );
  }

  private reRouteToLogin(state: RouterStateSnapshot) {
    this.router.navigate(ApplicationPaths.LoginPathComponents, {
      queryParams: {
        [QueryParameterNames.ReturnUrl]: state.url
      }
    });
  }
}
