import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateParserFormatter, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Address2, Client, RequestProfileBusinessProfileDetails, RequestProfileCommand, RequestProfileIndividualProfileDetails } from 'src/nswag';
import { DatePickerResult } from '../../date-picker/date-picker.component';
import { CountriesService } from 'src/app/utils/countries.service';
import { UtilityService } from '../../utilitities/utilities';
import { AlertService } from '../../_alert';
import { ProfileRequestService } from '../profile-request.service';

@Component({
  selector: 'app-profile-request',
  templateUrl: './profile-request.component.html',
  styleUrls: ['./profile-request.component.scss']
})
export class ProfileRequestComponent implements OnInit {

  @Input() client: Client;
  @Input() hasCredits: boolean;
  
  public fullName: string;
  public dateOfBirth: string;

  public gender: string;

  public isBusiness: boolean = false;
  public companyName: string;
  public companyNumber: string;

  public information: string;
  public disableAll = false;
  public dateIsValid = true;
  public nameMininumLength: number = 3;
  public infoMaxLength: number = 1024;

  public nationalityFocus: boolean = false;
  public jurisdictionFocus: boolean = false;
  public addressCountryFocus: boolean = false;

  public addressCountry: string;
  public nationality: string;
  public jurisdiction: string;

  public address: Address2;

  public modal: NgbModalRef;
  public loading: boolean = false;
  
  constructor(private activeModal: NgbActiveModal, private alertService: AlertService, private profileRequestService: ProfileRequestService, private dateFormatter: NgbDateParserFormatter, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    const client = this.client;
    this.fullName = client?.individual?.name
    this.dateOfBirth = client?.individual?.dateOfBirth;
    this.nationality = client?.individual?.nationality;
    this.gender = client?.individual?.gender;
    this.address = this.mapAddressModels(client?.address); 
    this.jurisdiction = client?.business?.jurisdiction;
    this.companyName = client?.business?.name;
    this.companyNumber = "";
    if (this.companyName !== undefined) {
      this.isBusiness = true;
    }
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  public hasErrors(): boolean {
    return (
      (!(this.fullName?.trim().length >= this.nameMininumLength) && !(this.companyName?.trim().length >= this.nameMininumLength)) ||
      !(this.information?.trim()?.length && this.information?.trim().length <= this.infoMaxLength)||
      !this.dateIsValid ||
      !this.isNationalityValid() ||
      !this.isJurisdictionValid() ||
      !this.isAddressCountryValid()
    );
  }

  public setClientType() {
    this.isBusiness = !this.isBusiness;
  }
  
  public onBlur(e: Event): void {
    this.isBusiness ? this.jurisdictionFocus = false : this.nationalityFocus = false;
    this.addressCountryFocus = false;
    e.stopPropagation();
  }

  public onFocus(e: Event): void {
    this.isBusiness ? this.jurisdictionFocus = true : this.nationalityFocus = true;
    this.addressCountryFocus = true;
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }
  
  public isNationalityValid(): boolean {
    if (!this.nationality) return true;
    if (CountriesService.getNationalityCode(this.nationality) || CountriesService.getNationalityName(this.nationality))
      return true;
    return false;
  }

  public isJurisdictionValid(): boolean {
    if (!this.jurisdiction) return true;
    if (CountriesService.getJurisdictionCode(this.jurisdiction) || CountriesService.getJurisdictionName(this.jurisdiction))
      return true;
    return false;
  }

  public isAddressCountryValid(): boolean {
    if (!this.addressCountry) return true;
    if (CountriesService.getJurisdictionCode(this.addressCountry) || CountriesService.getJurisdictionName(this.addressCountry))
      return true;
    return false;
  }

  public nationalityTypeAhead: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return this.nationalitySubSet(term);
      })
  );

  public countryTypeAhead: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return this.countrySubSet(term);
      })
  );

  public dateChanged(dateResult: DatePickerResult) {
    this.dateIsValid = dateResult.isValid;
    if (this.dateIsValid) {
      this.dateOfBirth = this.dateFormatter.format(dateResult.value);
    }
  }

  private mapAddressModels(input: Address2): Address2 {
    this.addressCountry = UtilityService.getCountryFromCode(input?.countryIsoCode);
    let address: Address2 = new Address2({
      line1: input?.line1 ?? '',
      line2: input?.line2 ?? '',
      city: input?.city ?? '',                       
      countryIsoCode: input?.countryIsoCode ?? '', // reassigned from countryName on submit
      county: input?.county ?? '',
      countyAbbrev: input?.countyAbbrev ?? '',
      postcode: input?.postcode ?? '',
      addressType: input?.addressType ?? ''
    });
   return address;
  }

  private countrySubSet(term: string) {
    return CountriesService.getJurisdictionSubSet(term);
  }

  private nationalitySubSet(term: string) {
    return CountriesService.getNationalitySubSet(term);
  }

  private submit(command: RequestProfileCommand) {
    this.loading = true;
    this.profileRequestService.submitRequest(command).subscribe(result => {
      if (result.data) {
        this.dismiss();
        this.disableAll = false;
        this.alertService.success("The 'More Information' request has been sent successfully", { autoClose: true, keepAfterRouteChange: false });   
      }
    },
    (error) => {
      this.disableAll = false;
      console.log(error);
      this.alertService.error('Unfortunately, the request failed. Please try again.', { autoClose: true, keepAfterRouteChange: true });
    }).add(() => {
      this.loading = false;
    });
  }

  public submitInvestigation() {
    this.disableAll = true;
    let command = new RequestProfileCommand();
    command.supportingInformation = this.information;
    this.address.countryIsoCode = UtilityService.getCodeFromCountry(this.addressCountry);
    if (this.isBusiness) {
      command.business = new RequestProfileBusinessProfileDetails({
        name: this.companyName,
        companyNumber: this.companyNumber,
        address: UtilityService.convertToAddress(this.address),
        jurisdiction: this.jurisdiction
      });
    }
    else {
      command.individual = new RequestProfileIndividualProfileDetails({
        name: this.fullName,
        dateOfBirth: this.dateOfBirth,
        nationality: this.nationality,
        gender: this.gender,
        address: UtilityService.convertToAddress(this.address)
      });
    }
    this.submit(command);
  }
}
