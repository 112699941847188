<app-problem-details></app-problem-details>

<div class="tenants-list style-background border">
  <button class="btn btn-secondary ms-3" id="addTenantBtn" (click)="showCreateTenantModal()">
    &plus; Add Tenant
  </button>
  <div *ngIf="tenantsList?.length > 0">
    <div class="col">
      <div class="row">
        <div class="col-sm-2">
          <strong>Tenant Name</strong>
        </div>
        <div class="col-sm-2">
          <strong>Company Name</strong>
        </div>
        <div class="col-sm-1">
          <strong>Hostname</strong>
        </div>
        <div class="col-sm-1" title="Active users / licenses">
          <strong>Users</strong>
        </div>
        <div class="col-sm-1">
          <strong>Demo</strong>
        </div>
        <div class="col-sm-1">
          <strong>License</strong>
        </div>
        <div class="col-sm-1"></div>
      </div>
      <div *ngFor="let tenant of tenantsList" class="row">
        <div class="col-sm-2">{{ tenant.name }}</div>
        <div class="col-sm-2" title="{{ tenant.description }}">{{ tenant.companyName }}</div>
        <div class="col-sm-1">{{ tenant.hostName }}</div>
        <div class="col-sm-1">{{ tenant.currentActiveUsers }} / {{ tenant.maxActiveUsers }}</div>
        <div class="col-sm-1">
          <input type="checkbox" [checked]="tenant.demo" disabled>
        </div>
        <div class="col-sm-1">{{ tenant.licenceType }}</div>
        <div class="col-sm-1">
          <div class="btn-group" role="group" aria-label="Action buttons">
            <button type="button" class="btn btn-primary" (click)="loadUserManagementForTenant(tenant)">Users</button>
            <button type="button" class="btn btn-secondary" (click)="showEditTenantModal(tenant)">Edit</button>
            <button type="button" class="btn btn-danger" (click)="showDeleteTenantConfirmModal(tenant)">Delete</button>
          </div>
        </div>        
      </div>
    </div>
  </div>  
</div>

<ng-template #deleteTenantModalContent>
  <div class="container">
    <div class="modal-header">
      <h3>Confirm</h3>
    </div>
    <div class="modal-body">
      <p>
        Are you sure you want to delete tenant '{{ selectedTenant?.name }}' ?
      </p>
      <button class="btn bg-dark text-light cancel-btn" (click)="dismissActiveModal()">
        Cancel
      </button>
      <button class="btn bg-danger text-light" id="deleteBtn" (click)="deleteTenant()">
        Delete
      </button>
    </div>
  </div>
</ng-template>

<ng-template #updateTenantModalContent>
  <div class="modal-header">
    <h3>Edit Tenant</h3>
  </div>
  <div class="modal-body form-group">
    <div *ngIf="canViewForm()" class="form" id="addTenantForm">
      <div class="container">
        <div class="row">
          <div class="col">
            <h5>Details</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row required-input">
              <label>Tenant Identifier</label>
            </div>
            <div class="row">
              <input type="text" class="form-control" required minlength="5" [(ngModel)]="selectedTenant.name" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row">
              <label>Tenant Description</label>
            </div>
            <div class="row">
              <input type="text" class="form-control" required minlength="5" [(ngModel)]="selectedTenant.description" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row required-input">
              <label>Company Name</label>
            </div>
            <div class="row">
              <input type="text" class="form-control" required minlength="1" [(ngModel)]="selectedTenant.companyName" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row required-input">
              <label>Maximum Active Users</label>
            </div>
            <div class="row">
              <input type="number" class="form-control" required minlength="1"
                [(ngModel)]="selectedTenant.maxActiveUsers" />
            </div>
          </div>
        </div>
        <hr class="divider" />
        <div class="row">
          <div class="col">
            <h5>Features</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div>
              <input type="checkbox" [(ngModel)]="screenFeature" class="form-check-input" title="Screening"
                id="screening" name="screening" />
              &nbsp;Screening&nbsp;
              <input type="checkbox" [(ngModel)]="monitorFeature" class="form-check-input" title="Monitoring"
                id="monitoring" name="monitoring" />
              &nbsp;Monitoring&nbsp;
              <input type="checkbox" [(ngModel)]="searchFeature" class="form-check-input" title="Search" id="search"
                name="search" />
              &nbsp;Search&nbsp;
              <input type="checkbox" [(ngModel)]="idvFeature" class="form-check-input" title="IDV" id="idv"
                name="idv" />
              &nbsp;IDV&nbsp;
              <input type="checkbox" [(ngModel)]="riskFeature" class="form-check-input" title="Assessment" id="risk"
                name="risk" />
              &nbsp;Assessment&nbsp;
              <input type="checkbox" [(ngModel)]="apiFeature" class="form-check-input" title="API" id="api"
                name="api" />
              &nbsp;API
              <input type="checkbox" [(ngModel)]="intelligenceFeature" class="form-check-input" title="Intelligence"
                id="intelligence" name="intelligence" />
              &nbsp;Intelligence
            </div>
          </div>
        </div>
        <div *ngIf="searchFeature then premiumSearchProviderOptions"></div>
        <div *ngIf="
            searchFeature || screenFeature || monitorFeature;
            then dataProviderOptions
          "></div>
        <hr class="divider" />
        <div class="row">
          <div class="col">
            <h5>Additional Options</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div>
              <input type="checkbox" [(ngModel)]="selectedTenant.demo" class="form-check-input" title="Demo" id="demo"
                name="demo" />
              &nbsp;Demo / Sales Tenant&nbsp;
            </div>
          </div>
        </div>
        <div class="btn-container">
          <button class="btn bg-dark text-light" id="dismissBtn" (click)="cancelForm()">
            Cancel
          </button>
          <button class="btn bg-primary text-light" (click)="updateTenant()" [disabled]="
              !selectedTenant?.name ||
              !selectedTenant?.companyName ||
              !maxUsersValid(selectedTenant?.maxActiveUsers) ||
              !dataProviderSettingsValid()
            ">
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addTenantModalContent>
  <div class="modal-header">
    <h3>Add New Tenant</h3>
  </div>
  <div class="modal-body form-group">
    <div *ngIf="canViewForm()" class="form" id="addTenantForm">
      <div class="container">
        <div class="row">
          <div class="col">
            <h5>Details</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row required-input">
              <label>Tenant Identifier</label>
            </div>
            <div class="row">
              <input type="text" class="form-control" required minlength="5"
                [(ngModel)]="addTenantCommand.tenantName" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row">
              <label>Tenant Description</label>
            </div>
            <div class="row">
              <input type="text" class="form-control" required minlength="5"
                [(ngModel)]="addTenantCommand.tenantDescription" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row required-input">
              <label>Company Name</label>
            </div>
            <div class="row">
              <input type="text" class="form-control" required minlength="2"
                [(ngModel)]="addTenantCommand.companyName" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row required-input">
              <label>Maximum Active Users</label>
            </div>
            <div class="row">
              <input type="number" class="form-control" required min="1" max="1000"
                [(ngModel)]="addTenantCommand.maximumActiveUsers" />
            </div>
          </div>
        </div>
        <hr class="divider" />
        <div class="row">
          <div class="col">
            <h5>Features</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div>
              <input type="checkbox" [(ngModel)]="screenFeature" class="form-check-input" title="Screening"
                id="screening" name="screening" />
              &nbsp;Screening&nbsp;
              <input type="checkbox" [(ngModel)]="monitorFeature" class="form-check-input" title="Monitoring"
                id="monitoring" name="monitoring" />
              &nbsp;Monitoring&nbsp;
              <input type="checkbox" [(ngModel)]="searchFeature" class="form-check-input" title="Search" id="search"
                name="search" />
              &nbsp;Search&nbsp;
              <input type="checkbox" [(ngModel)]="idvFeature" class="form-check-input" title="IDV" id="idv"
                name="idv" />
              &nbsp;IDV&nbsp;
              <input type="checkbox" [(ngModel)]="riskFeature" class="form-check-input" title="Assessment" id="risk"
                name="risk" />
              &nbsp;Assessment&nbsp;
              <input type="checkbox" [(ngModel)]="apiFeature" class="form-check-input" title="API" id="api"
                name="api" />
              &nbsp;API
              <input type="checkbox" [(ngModel)]="intelligenceFeature" class="form-check-input" title="Intelligence" id="intelligence"
                name="intelligence" />
              &nbsp;Intelligence
            </div>
          </div>
        </div>
        <div *ngIf="searchFeature then premiumSearchProviderOptions"></div>
        <div *ngIf="
            searchFeature || screenFeature || monitorFeature;
            then dataProviderOptions
          "></div>
        <hr class="divider" />
        <div class="row">
          <div class="col">
            <h5>Additional Options</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div>
              <input type="checkbox" [(ngModel)]="addTenantCommand.demo" class="form-check-input" title="Demo" id="demo"
                name="demo" />
              &nbsp;Demo / Sales Tenant&nbsp;
            </div>
          </div>
        </div>
        <div class="btn-container">
          <button class="btn bg-dark text-light" id="dismissBtn" (click)="cancelForm()">
            Cancel
          </button>
          <button class="btn bg-primary text-light" id="addBtn" (click)="addTenant()" [disabled]="
              !addTenantCommand?.tenantName ||
              !addTenantCommand?.companyName ||
              !maxUsersValid(addTenantCommand?.maximumActiveUsers) ||
              !dataProviderSettingsValid()
            ">
            Create
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #premiumSearchProviderOptions>
  <br>
  <div class="row">
    <div class="col">
      <input type="checkbox" [(ngModel)]="togglePremiumSearchProviderCommand.enable" class="form-check-input"
        [checked]="togglePremiumSearchProviderCommand.enable" />
      <label class="form-check-label" for="flexCheckDefault">
        Premium&nbsp;Search&nbsp;(Diligencia)
      </label>
    </div>
  </div>
</ng-template>

<ng-template #dataProviderOptions>
  <hr class="divider" />
  <div class="row">
    <div class="col">
      <h5>Fuzzy Matching Options</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-8">
      <label>Threshold Percentage</label>
      <input type="range" id="rangeInput" name="rangeInput" class="form-range feature-setting" min="65" max="100"
        [value]="configurableThreshold" [(ngModel)]="configurableThreshold" oninput="amount.value=rangeInput.value" />
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div class="input-group">
        <output type="text" class="form-control" id="amount" name="amount" for="rangeInput"
          value="configurableThreshold">{{ configurableThreshold }}</output>
        <div class="input-group-append">
          <span class="input-group-text">%</span>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-sm-8">
      <label>Date of Birth Matching Sensitivity</label>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div ngbDropdown id="dobMatchingDropdown">
        <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" placeholder="Select.."
          ngbDropdownToggle>
          {{ selectedDobOption }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <div *ngFor="let option of dobSensitivityOptions">
            <button ngbDropdownItem (click)="changeSelectedDropdownItem(option)">
              {{ option }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>