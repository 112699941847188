import { Injectable } from '@angular/core';
import { country_list } from '../models/country-list';

@Injectable({ providedIn: 'root' })
export class CountriesService {

    private static findMatch<T>(list: T[], key: keyof T, value: string, targetKey: keyof T): string {
        const lowerValue = value?.toLowerCase();
        const match = list.find(item => (item[key] as unknown as string).toLowerCase() === lowerValue);
        return match ? (match[targetKey] as unknown as string) : null;
    }

    public static getJurisdictionName(code: string): string {
        return this.findMatch(country_list.Countries, 'JurisdictionCode', code, 'JurisdictionName');
    }

    public static getJurisdictionCode(name: string): string {
        return this.findMatch(country_list.Countries, 'JurisdictionName', name, 'JurisdictionCode');
    }

    public static getNationalityName(code: string): string {
        return this.findMatch(country_list.Countries, 'CountryCode', code, 'Nationality');
    }

    public static getNationalityCode(name: string): string {
        return this.findMatch(country_list.Countries, 'Nationality', name, 'CountryCode');
    }

    public static getCountryName(code: string): string {
        return this.findMatch(country_list.Countries, 'CountryCode', code, 'CountryName');
    }

    public static getCountryCode(name: string): string {
        return this.findMatch(country_list.Countries, 'CountryName', name, 'CountryCode');
    }

    private static getSubSet<T>(list: T[], term: string, key: keyof T, alternateKey: keyof T, limit: number = 10): string[] {
        const lowerTerm = term.toLowerCase();
        const result: string[] = [];
        const seen = new Set<string>();

        if (term.length >= 2) {
            for (const item of list) {
                const primaryMatch = (item[key] as unknown as string).toLowerCase().includes(lowerTerm);
                const alternateMatch = (item[alternateKey] as unknown as string).toLowerCase().includes(lowerTerm);

                const keyValue = item[key] as unknown as string;

                if ((primaryMatch || alternateMatch) && !seen.has(keyValue)) {
                    result.push(keyValue);
                    seen.add(keyValue);
                    if (result.length >= limit) break;
                }
            }
        }

        return result.sort();
    }

    public static getNationalitySubSet(term: string): string[] {
        return this.getSubSet(country_list.Countries, term, 'Nationality', 'CountryCode');
    }

    public static getJurisdictionSubSet(term: string): string[] {
        return this.getSubSet(country_list.Countries, term, 'JurisdictionName', 'JurisdictionCode');
    }

    public static getCountrySubSet(term: string): string[] {
        return this.getSubSet(country_list.Countries, term, 'CountryName', 'CountryCode');
    }

    public static getJurisdictionSubSetIncludingAdditionalCodes(term: string): string[] {
        let result = this.getJurisdictionSubSet(term);
        country_list.Countries.forEach(v => {
            if (v.AlternativeCode?.length > 0) {
                if (v.AlternativeCode.toLowerCase().indexOf(term.toLowerCase()) > -1 && result.length < 10) {
                    if(result.indexOf(v.CountryName) == -1)
                        result.push(v.CountryName);
                }
            }
        });
        return result.sort();
    }
}
