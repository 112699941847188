import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class DatePickerResult {
  constructor( public value: NgbDateStruct, public isValid = false) {}
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnChanges{

  // It supports Dates and ISO formatted string dates
  @Input() dateOfBirth: any;
  @Output() dataEvent = new EventEmitter<DatePickerResult>();
  public ngbDate: NgbDateStruct;
  public invalidDate: boolean = false;

  constructor(private dateFormatter: NgbDateParserFormatter) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dateOfBirth) {
      if (this.dateOfBirth instanceof Date) {
        this.ngbDate.day = this.dateOfBirth.getUTCDay();
        this.ngbDate.month = this.dateOfBirth.getUTCMonth();
        this.ngbDate.year = this.dateOfBirth.getUTCFullYear();
      }
      else if (typeof this.dateOfBirth === "string" && this.dateOfBirth?.length === 10) {
        this.ngbDate = this.dateFormatter.parse(this.dateOfBirth);
      }
    }      
  }
 
  public onEditChange() {
    this.dataEvent.emit(new DatePickerResult(this.ngbDate, !this.invalidDate));
  }

  public onInputBlur() {
    this.invalidDate = this.ngbDate != null && isNaN(new Date(this.ngbDate.year,this.ngbDate.month-1, this.ngbDate.day).getDate());
    this.onEditChange();
  }
}
